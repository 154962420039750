import React from 'react';

export const MapIcons = () => (
  <div className="enz-map-icons">
    <div className="enz-map-icons__icon cabbage-trees">
      <span></span>
    </div>
    <div className="enz-map-icons__icon marae">
      <span></span>
    </div>
    <div className="enz-map-icons__icon sky-tower">
      <span></span>
    </div>
    <div className="enz-map-icons__icon clapper-board">
      <span></span>
    </div>
    <div className="enz-map-icons__icon geyser">
      <span></span>
    </div>
    <div className="enz-map-icons__icon surfer">
      <span></span>
    </div>
    <div className="enz-map-icons__icon grapes">
      <span></span>
    </div>
    <div className="enz-map-icons__icon coffee">
      <span></span>
    </div>
    <div className="enz-map-icons__icon canoe canoe-1">
      <span></span>
    </div>
    <div className="enz-map-icons__icon canoe canoe-2">
      <span></span>
    </div>
    <div className="enz-map-icons__icon mountain-1 mountain-1-1">
      <span></span>
    </div>
    <div className="enz-map-icons__icon mountain-1 mountain-1-2">
      <span></span>
    </div>
    <div className="enz-map-icons__icon skier">
      <span></span>
    </div>
    <div className="enz-map-icons__icon mountains mountains-1">
      <span></span>
    </div>
    <div className="enz-map-icons__icon jet-boat">
      <span></span>
    </div>
    <div className="enz-map-icons__icon mountains mountains-2">
      <span></span>
    </div>
    <div className="enz-map-icons__icon whale-tail">
      <span></span>
    </div>
    <div className="enz-map-icons__icon kea">
      <span></span>
    </div>
    <div className="enz-map-icons__icon snow-boarder">
      <span></span>
    </div>
    <div className="enz-map-icons__icon mountain-2">
      <span></span>
    </div>
    <div className="enz-map-icons__icon cyclist">
      <span></span>
    </div>
    <div className="enz-map-icons__icon penguin">
      <span></span>
    </div>
  </div>
);

import React, { useEffect, useState } from 'react';
import { RegistrationForm } from '../components/RegistrationForm';
import animateScrollTo from 'animated-scroll-to';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Toggle from '../components/Toggle';
import ShareButton from '../components/ShareButton';
import Article from '../components/Article';

import { ModuleCardContent, SignUpToNauMaiContent } from '../components/home-content';

const registrationFormIds = {
  wellbeing: 1887,
  exploring: 1889,
  working: 1890
};

const stageLabels = {
  wellbeing: 'Find new ways to stay healthy and stay connected',
  exploring: 'Experience a great Kiwi summer exploring new places',
  working: 'Stay in New Zealand and build new skills'
};

function Home({ location }) {
  const [selection, setSelection] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const fromMap = location?.state?.fromMap;

  const hash = window.location.hash;
  // deeplink to selection
  useEffect(() => {
    if (hash) {
      const selections = Object.keys(stageLabels);
      const hashValue = hash.split('#')[1].split('/')[0];

      if (selections.includes(hashValue)) {
        setSelection(hashValue);
      }
      if (fromMap || hashValue === 'neutral') {
        setTimeout(() => {
          scrollToRegForm();
        }, 700);
      }
    }
  }, [hash, fromMap]);

  // Attach share button when it appears
  useEffect(() => {
    setTimeout(() => {
      window.addthis.button('.share');
    }, 500);
  }, [selection, formSuccess]);

  const handleSelection = (newSelection) => {
    setSelection(newSelection);

    // set hash
    window.location.hash = newSelection;

    setTimeout(
      () => {
        animateScrollTo(document.querySelector('#content'), {
          speed: 2000
        });
      },
      selection ? 200 : 700
    );
  };

  const scrollToRegForm = () => {
    animateScrollTo(document.querySelector('#registration'));
  };

  return (
    <>
      <Header />

      <Banner
        active={selection !== null && selection !== 'neutral'}
        images={{
          sm: require('../assets/images/generated/banners/home-600.jpeg'),
          md: require('../assets/images/generated/banners/home-1440.jpeg')
        }}>
        <h1>What will your kiwi summer look like?</h1>
        <h3>Stay well, stay connected and have a great summer in Aotearoa New Zealand</h3>
      </Banner>

      <section className="section section--toggle">
        <Toggle selection={selection} onChange={handleSelection} />

        <div id="content"></div>

        <div className="container hidden-xs">
          {!selection && (
            <p className="lead">
              Experience summer the Kiwi way. Explore, travel, make friends, study, develop new
              skills – you&rsquo;ll find plenty of opportunities to stay active and connected during
              your&nbsp;break.
            </p>
          )}

          {selection && <p className="lead lead--intro">{stageLabels[selection]}</p>}
        </div>
      </section>

      {/* START wellbeing */}
      {selection && selection === 'wellbeing' && (
        <>
          <section className="section section--blue">
            <div className="container">
              <h2>STAY FOR SUMMER AND STAY WELL</h2>
              <p className="lead">
                Spending the summer in New Zealand? Just remember things will be a bit different,
                because if you leave New Zealand you may not be able to return. The good news is
                you&rsquo;re in a friendly country with plenty of ways to look after your wellbeing.
              </p>
              <p className="lead">
                You can take a break and explore the country and the culture, find summer
                employment, or find new ways to stay healthy and happy. There are plenty of ways you
                can learn, stay connected and make the most of the New Zealand summer.
              </p>
            </div>
          </section>

          {/* Fairs module  */}
          <SignUpToNauMaiContent scrollToRegForm={scrollToRegForm} />

          {/* Content Modules */}
          <section className="section section--grey">
            <div className="container">
              <h2 className="section--margin-btm">Get set to stay in New Zealand</h2>
              <div className="grid">
                {/* Renew your study visa module */}
                <div className="column">
                  <ModuleCardContent
                    title="Remember to apply for your new visa"
                    href="https://www.immigration.govt.nz/new-zealand-visas"
                    image={require('../assets/images/generated/cards/ENZ_ORIENTATION_G9C4717-320.jpeg')}
                    paragraph1="Make sure you apply for your new visa in time so you can legally stay in New&nbsp;Zealand."
                    paragraph2="Check whether you have everything you need for your&nbsp;application."
                    ctaText="Apply now"
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Hear from other international students"
                    href="https://www.instagram.com/studyinnewzealand/"
                    image={require('../assets/images/generated/cards/ENZ_ORIENTATION_G9C4334-320.jpeg')}
                    paragraph1="Check out our Instagram account to connect with our Kiwi Ambassadors. They are international students who share their study in New Zealand experience, answer questions and offer advice."
                    ctaText="Learn More"
                    isSecondary
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="GET SUPPORT WITH YOUR PERSONAL WELLBEING"
                    href="https://naumainz.studyinnewzealand.govt.nz/help-and-advice/personal-wellbeing/feeling-lonely-homesick-or-depressed"
                    image={require('../assets/images/generated/cards/038_Studyinnewzealand_Universities-730.jpeg')}
                    paragraph1="Living in a different country can be a challenge. It&rsquo;s normal to sometimes feel lonely or homesick. If you&rsquo;re feeling down, there are ways to improve your wellbeing and there are people who can help."
                    ctaText="Learn more"
                    isFullWidth
                    isGrey
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Stay well and happy in New Zealand"
                    href="https://naumainz.studyinnewzealand.govt.nz/help-and-advice/personal-wellbeing/stay-well-stay-connected/?utm_source=nzsummer&utm_medium=referral&utm_content=lp_staying&utm_campaign=nzsummer&nogeo=1&mkt_tok=1"
                    image={require('../assets/images/generated/cards/article_2-320.jpeg')}
                    paragraph1="Get trusted information and advice to have a great experience living and studying in New Zealand."
                    paragraph2="Hear from other international students on how they stay well and stay connected."
                    ctaText="Learn more"
                    isSecondary
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Explore further study options"
                    href="https://www.studywithnewzealand.govt.nz/study-options/new-ways-of-learning"
                    image={require('../assets/images/generated/cards/take-your-studies-further-320.png')}
                    paragraph1="Have you considered continuing study so you&rsquo;re better prepared for our changing
              world? Explore further study and additional resources to help with your decision
              making and&nbsp;application."
                    ctaText="Learn More"
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="Tū Ngātahi"
                    href="https://naumainz.studyinnewzealand.govt.nz/tu-ngatahi/?utm_source=nzsummer&utm_medium=referral&utm_content=Ip_staying_finishing_deciding&utm_campaign=nzsummer"
                    image={require('../assets/images/generated/cards/tungatahi_v2_2x-730.png')}
                    paragraph1="Tū Ngātahi is a te Reo Māori phrase that calls us to stand together, and this is our way of saying that we ‘stand together’ with you. It is a gesture of unity, to show that you are welcomed and supported here in Aotearoa New Zealand."
                    ctaText="Learn more"
                    isTertiary
                    isFullWidth
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Blog */}
          <section className="section">
            <div className="container">
              <h2>From the blog</h2>
              <div className="grid grid--3">
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/studying/how-to-stay-productive-strong-and-calm"
                    target="_blank"
                    author="Mellisa Chin Lee"
                    age="1 year ago"
                    title="How to stay productive, strong and calm"
                    image={require('../assets/images/generated/blogs/how-to-stay-productive-strong-and-calm-300.jpeg')}
                    category="studying"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/lifestyle/ways-to-cope-with-stress"
                    target="_blank"
                    author="Shirleen Prasad"
                    age="11 months ago"
                    title="7 ways to cope with stress"
                    image={require('../assets/images/generated/blogs/7-ways-to-cope-with-stress-300.jpeg')}
                    category="lifestyle"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/tips/things-weve-learnt-about-ourselves-while-studying-abroad"
                    target="_blank"
                    author="ENZ Kiwi Ambassadors"
                    age="3 years ago"
                    title="Learning about ourselves while studying abroad"
                    image={require('../assets/images/generated/blogs/learning-about-ourselves-300.jpeg')}
                    category="tips"
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* END wellbeing */}
      {/* START Exploring */}
      {selection && selection === 'exploring' && (
        <>
          <section className="section section--blue">
            <div className="container">
              <h2>STAY FOR SUMMER AND SEE THE COUNTRY</h2>
              <p className="lead">
                Spending the summer in New Zealand? Just remember things will be a bit different,
                because if you leave New Zealand you may not be able to return. The good news is
                you&rsquo;re in a friendly country with plenty of new things to discover.
              </p>
              <p className="lead">
                You could take a break and look after your wellbeing, get out and see the country
                and experience the culture, or find a summer job. There are plenty of ways you can
                explore, stay connected and make the most of the New Zealand summer.
              </p>
            </div>
          </section>

          {/* Fairs module  */}
          <SignUpToNauMaiContent scrollToRegForm={scrollToRegForm} />
          {/* Content Modules */}
          <section className="section section--grey">
            <div className="container">
              <h2 className="section--margin-btm">Get set to stay in New Zealand</h2>
              <div className="grid">
                <div className="column">
                  <ModuleCardContent
                    title="Remember to apply for your new visa"
                    href="https://www.immigration.govt.nz/new-zealand-visas"
                    image={require('../assets/images/generated/cards/ENZ_ORIENTATION_G9C4717-320.jpeg')}
                    paragraph1="Make sure you apply for your new visa in time so you can legally stay in New&nbsp;Zealand."
                    paragraph2="Check whether you have everything you need for your&nbsp;application."
                    ctaText="Apply now"
                    isGrey
                  />
                </div>
                {/* Regions module  */}
                <div className="column">
                  <ModuleCardContent
                    selection={selection}
                    href="/map"
                    title="What's happening around New Zealand this summer?"
                    image={require('../assets/images/generated/cards/5079_QT_Scenic_7251-320.jpeg')}
                    paragraph1="See what&rsquo;s on in your area and throughout the country – and how you can join&nbsp;in."
                    ctaText="Discover more"
                    isSecondary
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="See more of Aotearoa New Zealand over summer"
                    href="https://naumainz.studyinnewzealand.govt.nz/help-and-advice/exploring-new-zealand"
                    image={require('../assets/images/generated/cards/059_Studyinnewzealand_Universities-730.jpeg')}
                    paragraph1="The summer holidays are a perfect time to travel and see more of the country. Get advice and plan ahead, so you can explore safely."
                    ctaText="Start exploring"
                    isFullWidth
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Learn about and experience Māori culture"
                    href="https://naumainz.studyinnewzealand.govt.nz/goals/learn-about-maori-culture"
                    image={require('../assets/images/generated/cards/019_Studyinnewzealand_Universities-320.jpeg')}
                    paragraph1="In Aotearoa New Zealand, Māori culture and language are an essential part of our identity. Explore ways to continue learning about and experiencing Māori culture, language and values."
                    ctaText="Learn more"
                    isSecondary
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Explore further study options"
                    href="https://www.studywithnewzealand.govt.nz/study-options/new-ways-of-learning"
                    image={require('../assets/images/generated/cards/take-your-studies-further-320.png')}
                    paragraph1="Have you considered continuing study so you&rsquo;re better prepared for our changing
              world? Explore further study and additional resources to help with your decision
              making and&nbsp;application."
                    ctaText="Learn More"
                    isGrey
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="Tū Ngātahi"
                    href="https://naumainz.studyinnewzealand.govt.nz/tu-ngatahi/?utm_source=nzsummer&utm_medium=referral&utm_content=Ip_staying_finishing_deciding&utm_campaign=nzsummer"
                    image={require('../assets/images/generated/cards/tungatahi_v2_2x-730.png')}
                    paragraph1="Tū Ngātahi is a te Reo Māori phrase that calls us to stand together, and this is our way of saying that we ‘stand together’ with you. It is a gesture of unity, to show that you are welcomed and supported here in Aotearoa New Zealand."
                    ctaText="Learn More"
                    isTertiary
                    isFullWidth
                  />
                </div>
              </div>
            </div>
          </section>
          {/* Blog */}
          <section className="section">
            <div className="container">
              <h2>From the blog</h2>
              <div className="grid grid--3">
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/lifestyle/how-discovering-maori-culture-helped-me-explore-my-own"
                    target="_blank"
                    author="Linley Boniface"
                    age="4 years ago"
                    title="How discovering Māori culture helped me explore my own"
                    image={require('../assets/images/generated/blogs/discovering-maori-culture-300.jpeg')}
                    category="lifestyle"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/tips/how20to20stay20safe20while20you20are20studying20in20new20zealand"
                    target="_blank"
                    author="Linley Boniface"
                    age="3 years ago"
                    title="How to stay safe while you are studying in New Zealand"
                    image={require('../assets/images/generated/blogs/how-to-stay-safe-300.jpeg')}
                    category="tips"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/tips/tips-for-the-weekend-traveller"
                    target="_blank"
                    author="Nathan Rees"
                    age="2 years ago"
                    title="Tips for the weekend traveller"
                    image={require('../assets/images/generated/blogs/tips-for-the-weekend-traveller-300.jpeg')}
                    category="tips"
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* END Exploring */}
      {/* START Working */}
      {selection && selection === 'working' && (
        <>
          <section className="section section--blue">
            <div className="container">
              <h2>STAY FOR SUMMER AND FIND EMPLOYMENT</h2>
              <p className="lead">
                Spending the summer in New Zealand? Just remember things will be a bit different,
                because if you leave New Zealand you may not be able to return. The good news is
                you&rsquo;re in a friendly country with good opportunities for summer work.
              </p>
              <p className="lead">
                Finding a job is a great way to build new skills and look after your wellbeing over
                summer. You can also take time out to explore New Zealand. There are plenty of ways
                you can develop your skills, experience workplace culture and make the most of the
                New Zealand summer.
              </p>
            </div>
          </section>
          {/* Fairs module  */}
          <SignUpToNauMaiContent scrollToRegForm={scrollToRegForm} />
          {/* Content Modules */}
          <section className="section section--grey">
            <div className="container">
              <h2 className="section--margin-btm">Get set to stay in New Zealand</h2>
              <div className="grid">
                {/* Renew your study visa module */}
                <div className="column">
                  <ModuleCardContent
                    title="Remember to apply for your new visa"
                    href="https://www.immigration.govt.nz/new-zealand-visas"
                    image={require('../assets/images/generated/cards/ENZ_ORIENTATION_G9C4717-320.jpeg')}
                    paragraph1="Make sure you apply for your new visa in time so you can legally stay in New&nbsp;Zealand."
                    paragraph2="Check whether you have everything you need for your&nbsp;application."
                    ctaText="Apply now"
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Build your employability skills for the future"
                    href="http://naumainz.studyinnewzealand.govt.nz/help-and-advice/working/employability/?utm_source=nzsummer&utm_medium=referral&utm_content=lp_finishing_deciding&utm_campaign=nzsummer&nogeo=1&mkt_tok=1"
                    image={require('../assets/images/generated/cards/1932ENZ_ThinkNew_New Workflows-Option1-P-320.jpeg')}
                    paragraph1="It&rsquo;s never too early to start preparing for your future. Get advice on networking, writing your CV (resumé) and cover letter, preparing for a job interview and more."
                    ctaText="Discover More"
                    isGrey
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="Expand your opportunities with a summer job"
                    href="https://naumainz.studyinnewzealand.govt.nz/goals/get-a-part-time-job"
                    image={require('../assets/images/generated/cards/053_Studyinnewzealand_Universities-730.jpeg')}
                    paragraph1="Working over the summer is a great way to get career experience, build your network and learn about workplace culture."
                    paragraph2="Check out our useful tips for finding employment."
                    ctaText="Learn more"
                    isFullWidth
                    isSecondary
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Working while on a student visa"
                    href="https://naumainz.studyinnewzealand.govt.nz/help-and-advice/working/working-on-a-student-visa"
                    image={require('../assets/images/generated/cards/5079_Wellington_LCB_6494-320.jpeg')}
                    paragraph1="Working in a summer job is an excellent opportunity to gain practical workplace skills. Just make sure you understand what you can and can&rsquo;t do on your student visa."
                    ctaText="Learn More"
                    isGrey
                  />
                </div>
                <div className="column">
                  <ModuleCardContent
                    title="Explore further study options"
                    href="https://www.studywithnewzealand.govt.nz/study-options/new-ways-of-learning"
                    image={require('../assets/images/generated/cards/take-your-studies-further-320.png')}
                    paragraph1="Have you considered continuing study so you&rsquo;re better prepared for our changing world? Explore further study and additional resources to help with your decision making and&nbsp;application."
                    ctaText="Learn More"
                  />
                </div>
                <div className="column column--span">
                  <ModuleCardContent
                    title="Tū Ngātahi"
                    href="https://naumainz.studyinnewzealand.govt.nz/tu-ngatahi/?utm_source=nzsummer&utm_medium=referral&utm_content=Ip_staying_finishing_deciding&utm_campaign=nzsummer"
                    image={require('../assets/images/generated/cards/tungatahi_v2_2x-730.png')}
                    paragraph1="Tū Ngātahi is a te Reo Māori phrase that calls us to stand together, and this is our way of saying that we ‘stand together’ with you. It is a gesture of unity, to show that you are welcomed and supported here in Aotearoa New Zealand."
                    ctaText="Learn More"
                    isTertiary
                    isFullWidth
                  />
                </div>
              </div>
            </div>
          </section>
          {/* Blog */}
          <section className="section">
            <div className="container">
              <h2>From the blog</h2>
              <div className="grid grid--3">
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/working/what-future-focused-employers-are-looking-for"
                    target="_blank"
                    author="Morgan Bailey"
                    age="2 years ago"
                    title="What future-focused employers are looking for"
                    image={require('../assets/images/generated/blogs/what-future-focused-companies-look-for-when-hiring-300.jpeg')}
                    category="working"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/working/how-volunteering-can-boost-your-employability"
                    target="_blank"
                    author="Divya Kataria"
                    age="11 months ago"
                    title="How volunteering can boost your employability"
                    image={require('../assets/images/generated/blogs/how-volunteering-can-boost-your-employability-300.jpeg')}
                    category="working"
                  />
                </div>
                <div className="column">
                  <Article
                    href="https://www.studyinnewzealand.govt.nz/blog/category/working/how-to-identify-your-job-skills-and-abilities"
                    target="_blank"
                    author="John Dorado, Divya Kataria and Marie Hwang"
                    age="11 months ago"
                    title="How to identify your job skills and abilities"
                    image={require('../assets/images/generated/blogs/how-to-identify-your-skills-300.jpeg')}
                    category="working"
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* END Working */}

      {selection && (
        <>
          {/* Register for Your Great Kiwi Summer Fair */}
          <section id="registration" className="section section--secondary">
            <div className="container">
              {formSuccess ? (
                <div className="registration-form-success">
                  <h3 className="text-white">You&rsquo;ve joined NauMai NZ</h3>
                  <div className="registration-form-success__content">
                    <p>
                      Kia ora, welcome.
                      <br /> We&rsquo;re here to support you to have a great experience living and
                      studying in New Zealand.
                    </p>
                    <a
                      href="https://naumainz.studyinnewzealand.govt.nz/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Visit NauMai NZ
                    </a>
                  </div>

                  <ShareButton reverse>Share this page</ShareButton>
                </div>
              ) : (
                <>
                  <h2 className="text-white">Sign Up to NauMai NZ</h2>
                  <p className="lead text-white mb-30">
                    Fill out your details to sign up to NauMai NZ, receive help and advice on
                    staying happy and healthy, employability and what&rsquo;s going on in New
                    Zealand over summer!
                  </p>
                  <RegistrationForm
                    formId={registrationFormIds[selection]}
                    selection={selection}
                    formSuccessHandler={setFormSuccess}
                  />
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Home;

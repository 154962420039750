import React from 'react';
import Banner from '../components/Banner';
import Header from '../components/Header';
import { ButtonLink } from '../components/ButtonLink';
import { Chevron } from '../svgs';

function NotFound() {
  return (
    <>
      <Header child="Page not found" />

      <Banner noShare>
        <h1>Page not found</h1>
      </Banner>

      <div className="section">
        <div className="container text-centered">
          <p className="lead">Sorry, we can&rsquo;t find the page you&rsquo;re looking&nbsp;for.</p>
          <ButtonLink url="/" text="Return to home page" icon={Chevron} buttonColor="secondary" />
        </div>
      </div>
    </>
  );
}

export default NotFound;

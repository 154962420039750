import React from 'react';
import { Chevron } from '../../svgs';

const SignUpToNauMaiContent = (props) => {
  const { scrollToRegForm } = props;

  return (
    <>
      <section className="section--dark-grey">
        <div className="countdown-timer">
          <div className="countdown-timer__splash" />
          <div className="countdown-timer__timer">
            <h3 className="countdown-timer__title countdown-timer--spacing text-white">
              Sign Up to NauMai NZ
            </h3>

            <p className="countdown-timer--spacing-x2">
              NauMai NZ is the official government site for international students studying in New
              Zealand. Get trusted information, help and advice to have a great experience living
              and studying in New Zealand.
            </p>

            <button
              type="button"
              className="button button--primary-inverse"
              onClick={scrollToRegForm}>
              <span>Sign Up</span>
              <span className="icon">
                <Chevron />
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpToNauMaiContent;

import React from 'react';
import classNames from 'classnames';
import { Share } from '../svgs';

function ShareButton({ children, reverse }) {
  const classes = classNames('share', {
    'share--reverse': !!reverse
  });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={classes}>
        <Share />
        {children}
      </a>
    </>
  );
}

export default ShareButton;

import React from 'react';

export const Close = () => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M43.0001 38.1123C44.0736 39.1858 44.0736 40.9263 43.0001 41.9998C41.9266 43.0733 40.1861 43.0733 39.1126 41.9998L26.9998 29.8869L14.8869 41.9998C13.8134 43.0733 12.0729 43.0733 10.9995 41.9998C9.92596 40.9263 9.92596 39.1858 10.9995 38.1123L23.1123 25.9995L11.0001 13.8872C9.92658 12.8138 9.92658 11.0733 11.0001 9.99979C12.0736 8.9263 13.814 8.9263 14.8875 9.99979L26.9998 22.112L39.112 9.99979C40.1855 8.9263 41.926 8.9263 42.9995 9.99979C44.073 11.0733 44.073 12.8138 42.9995 13.8872L30.8872 25.9995L43.0001 38.1123Z"
        fill="white"
      />
    </g>
  </svg>
);

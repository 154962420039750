import React from 'react';
import { MapSVG } from './MapSVG';
import { MapLabels } from './MapLabels';
import { MapIcons } from './MapIcons';
import ScaleImage from '../../assets/images/map/scale.svg';

export const Map = (props) => {
  return (
    <div className="enz-map">
      <div className="enz-map__content">
        <img alt="Map Scale" className="enz-map__scale" src={ScaleImage} />
        <MapIcons />
        <MapLabels {...props} />
        <MapSVG />
      </div>
      <div className="enz-map__background"></div>
    </div>
  );
};

import React from 'react';
import { Link } from '@reach/router';
import Logo from '../svgs/Logo';
import ThinkNewLogo from '../svgs/ThinkNewLogo';

const CAMPAIGN_PAGE_NAME = 'Summer in NZ';

function Header(props) {
  const { child, showHomeLink } = props;

  return (
    <header className="header">
      <div className="header__left">
        <a
          href="https://naumainz.studyinnewzealand.govt.nz/"
          className="header__logo"
          target="_blank"
          rel="noopener noreferrer"
          title="NauMai NZ">
          <Logo />
        </a>

        <ul className="breadcrumbs">
          <li>
            <a
              href="https://naumainz.studyinnewzealand.govt.nz/"
              target="_blank"
              rel="noopener noreferrer"
              title="NauMai NZ">
              Home
            </a>
          </li>
          <li>
            <Link to="/">{CAMPAIGN_PAGE_NAME}</Link>
          </li>
          {!!child && (
            <li>
              <span>{child}</span>
            </li>
          )}
        </ul>
        {showHomeLink && (
          <Link className="header__home-link" to="/">
            {CAMPAIGN_PAGE_NAME}
          </Link>
        )}
      </div>

      <div className="header__right">
        <a
          href="https://naumainz.studyinnewzealand.govt.nz/"
          className="header__think-new-logo"
          target="_blank"
          rel="noopener noreferrer"
          title="Think New - New Zealand Education">
          <ThinkNewLogo />
        </a>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';

export const Arrow = () => (
  <svg width="307" height="46" viewBox="0 0 307 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9706 44.2132L0.878693 25.1213C-0.292908 23.9498 -0.292908 22.0503 0.878693 20.8787L19.9706 1.7868C21.1422 0.615227 23.0416 0.615227 24.2132 1.7868C25.3848 2.95837 25.3848 4.85787 24.2132 6.02944L10.2426 20L307 20V26L10.2426 26L24.2132 39.9706C25.3848 41.1421 25.3848 43.0416 24.2132 44.2132C23.0416 45.3848 21.1422 45.3848 19.9706 44.2132Z"
      fill="#071937"
    />
  </svg>
);

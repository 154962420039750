import React from 'react';

export const Chevron = () => (
  <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.70224e-05 2.51302C-0.00149379 2.16656 0.0975868 1.82518 0.287792 1.53336C0.478221 1.2412 0.752561 1.01052 1.07868 0.876765C1.40522 0.742832 1.76487 0.713981 2.10901 0.795206C2.4496 0.875594 2.75443 1.05884 2.98662 1.3159L11.5737 10.5434C11.8803 10.8726 12.0469 11.3066 12.0469 11.7523C12.0469 12.198 11.8804 12.6318 11.5739 12.961L2.98496 22.1906C2.75363 22.4441 2.45132 22.6248 2.114 22.7046C1.77424 22.785 1.41915 22.7581 1.09562 22.6284C0.772473 22.4989 0.499013 22.2744 0.306603 21.9887C0.114407 21.7033 0.0104566 21.3681 0.00448882 21.026C0.000663172 20.7956 0.0413342 20.5653 0.124853 20.3499C0.207533 20.1367 0.330933 19.9407 0.489342 19.774L7.95355 11.7523L0.491006 3.73249C0.175398 3.40362 0.0022251 2.96472 1.70224e-05 2.51302Z"
      fill="currentColor"
    />
  </svg>
);

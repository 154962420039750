import React, { useEffect, useRef } from 'react';
import { REGIONS } from '../../data/regions';
import classNames from 'classnames';
import { Chevron, Close, MinusCircle, PlusCircle } from '../../svgs';
import { ButtonLink } from '../ButtonLink';
import { RegionImage } from './RegionImage';
import animateScrollTo from 'animated-scroll-to';

const RegionInfoPanel = ({ selectedRegion, setSelectedRegion }) => {
  const { title, description, image, ctaUrl } = selectedRegion;

  return (
    <div className="enz-region-info">
      <div className="enz-region-info__close">
        <button type="button" className="icon-button" onClick={() => setSelectedRegion(null)}>
          <span className="icon">
            <Close />
          </span>
        </button>
      </div>
      {image && <RegionImage image={image} alt={title} />}
      <div className="enz-region-info__content">
        <h2
          className="enz-region-info__content__title text-left"
          dangerouslySetInnerHTML={{ __html: title }}></h2>
        {description && (
          <div
            className="enz-region-info__content__desc"
            dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
        {ctaUrl && (
          <ButtonLink
            url={ctaUrl}
            text="Explore Region"
            icon={Chevron}
            buttonColor="secondary"
            openInNewWindow={true}
          />
        )}
      </div>
    </div>
  );
};

export const RegionInfo = (props) => {
  const { selectedRegion, setSelectedRegion } = props;

  // store collection of refs
  const regionRefs = useRef(REGIONS.map(() => React.createRef()));
  // find index of selected region
  const selectedIndex = REGIONS.findIndex((region) => region.id === selectedRegion?.id);

  // Animate when selected region changes
  useEffect(() => {
    // scroll to ref
    if (regionRefs?.current[selectedIndex]) {
      // scrollToRef(regionRefs?.current[selectedIndex]);
      animateScrollTo(regionRefs?.current[selectedIndex].current);
    } else {
      // scroll to top of page
      animateScrollTo(0);
    }
  }, [selectedIndex, regionRefs]);

  return (
    <div className="enz-region-info-list">
      {REGIONS.map((region, index) => {
        const isSelected = selectedRegion && selectedRegion.id === region.id;
        return (
          <div
            ref={regionRefs.current[index]}
            className={classNames('enz-region-info-list__region', {
              'is-selected': isSelected
            })}
            key={region.id}>
            <button
              type="button"
              className={classNames(`enz-region-info-list__trigger ${region.labelAlign}`, {
                'is-selected': region.id === selectedRegion?.id
              })}
              onClick={() => {
                if (isSelected) {
                  setSelectedRegion(null);
                } else {
                  setSelectedRegion(region);
                }
              }}>
              <span dangerouslySetInnerHTML={{ __html: region.title }}></span>
              {isSelected && (
                <span className="icon">
                  <MinusCircle />
                </span>
              )}
              {!isSelected && (
                <span className="icon">
                  <PlusCircle />
                </span>
              )}
            </button>
            {isSelected && <RegionInfoPanel {...props} />}
          </div>
        );
      })}
    </div>
  );
};

import React from 'react';

export const Marker = () => (
  <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M24.3519 7.90605C23.4999 6.17463 22.2301 4.68122 20.6565 3.55983C19.5514 2.70907 17.7948 1.76976 16.4571 1.37143C14.1714 0.690803 12.6147 0.722082 10.423 1.10326C9.14403 1.35515 7.9023 1.76787 6.72757 2.33154C5.7337 2.84062 4.81008 3.47578 3.97969 4.22121C2.70289 5.39311 1.67224 6.8062 0.947544 8.37847C0.352948 9.78565 0.0314174 11.2925 0 12.8192C0.000625549 14.755 0.45489 16.6639 1.32656 18.3937C3.70721 23.1179 12.7918 31.9993 12.7918 31.9993C12.7918 31.9993 21.9637 23.5061 24.6362 17.7323C25.3169 16.1745 25.6443 14.4861 25.5952 12.7875C25.546 11.089 25.1215 9.42215 24.3519 7.90605Z"
        fill="#22A2A2"
      />
      <path
        d="M12.8002 18.2857C11.3453 18.2857 9.94995 17.7078 8.92118 16.679C7.89241 15.6502 7.31445 14.2549 7.31445 12.8C7.31445 11.3451 7.89241 9.94978 8.92118 8.92101C9.94995 7.89224 11.3453 7.31429 12.8002 7.31429C14.2551 7.31429 15.6504 7.89224 16.6792 8.92101C17.7079 9.94978 18.2859 11.3451 18.2859 12.8C18.2859 14.2549 17.7079 15.6502 16.6792 16.679C15.6504 17.7078 14.2551 18.2857 12.8002 18.2857Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25.6" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from 'react';
import classNames from 'classnames';
import ShareButton from '../components/ShareButton';

function Banner({ children, images, active, noShare }) {
  const classes = classNames('banner', {
    'banner--noimage': !images,
    'banner--active': active
  });

  return (
    <div className={classes}>
      {images && (
        <div className="banner__image">
          <picture>
            <source srcSet={images.md} media="(min-width: 900px)" />
            <img src={images.sm} alt="" />
          </picture>
          <div className="banner__overlays">
            <div className="banner__overlay banner__overlay--0"></div>
            <div className="banner__overlay banner__overlay--1"></div>
            <div className="banner__overlay banner__overlay--2"></div>
            <div className="banner__overlay banner__overlay--3"></div>
            <div className="banner__overlay banner__overlay--4"></div>
          </div>
        </div>
      )}
      <div className="banner__content">{children}</div>

      {!noShare && (
        <div className="banner__share">
          <ShareButton>Share</ShareButton>
        </div>
      )}
    </div>
  );
}

export default Banner;

import React, { useRef, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import animateScrollTo from 'animated-scroll-to';

export const RegistrationForm = (props) => {
  const { formId, selection, formSuccessHandler } = props;

  const formRef = useRef();

  const scrollToThankYou = () => {
    setTimeout(() => {
      animateScrollTo(document.querySelector('#registration'), {
        speed: 2000
      });
    }, 100);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.MktoForms2) {
      window.MktoForms2.loadForm('//app-sn03.marketo.com', '579-ITT-172', formId, function (form) {
        if (formRef.current) {
          formRef.current.innerHTML = '';
          formRef.current.appendChild(form.render()[0]);
        }

        // Add an onSuccess handler
        form.onSuccess(function () {
          //GTM  PRep and send tracking
          const tagManagerArgs = {
            dataLayer: {
              event: 'formSubmitted',
              formAction: 'Sign-up',
              formLabel: 'NauMai',
              landingPageSelection: `${selection.toLowerCase()}`
            }
          };
          TagManager.dataLayer(tagManagerArgs);

          // Get the form's jQuery element and hide it
          form.getFormElem().hide();
          scrollToThankYou();

          // notify parent of successful submission
          formSuccessHandler(true);
          // Return false to prevent the submission handler from taking the lead to the follow up url
          return false;
        });
      });
    }
  }, [formId, selection, formSuccessHandler]);

  return <section ref={formRef} className="marketoFormWrapper"></section>;
};

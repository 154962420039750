import React, { useState } from 'react';
import { Map } from './Map';
import { RegionInfo } from './RegionInfo';
import { Arrow } from '../../svgs';

export const RegionMap = () => {
  const [selectedRegion, setSelectedRegion] = useState();

  return (
    <div className="enz-region-map">
      <div className="enz-region-map__map">
        <div className="enz-region-map__map__padding">
          <Map selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} />
        </div>
      </div>
      <div className="enz-region-map__list">
        <RegionInfo selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} />
        <div className="enz-region-map__placeholder">
          <div className="enz-region-map__placeholder__content  text-centered">
            <h2 className="text-white">Choose a place</h2>
            <div className="enz-region-map__placeholder__icon">
              <span className="icon">
                <Arrow />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';
import { Link } from '@reach/router';

const CardContent = ({ children, title, image }) => {
  return (
    <>
      <div className="card__image">
        <img src={image} alt="" />
      </div>
      <div className="card__content">
        <h3 className="text-white">{title}</h3>
        <div className="card__content__inner">{children}</div>
      </div>
    </>
  );
};

const Card = ({
  large,
  linkTo,
  linkState = {},
  isSecondary = false,
  isTertiary = false,
  isGrey = false,
  ...rest
}) => {
  const classses = classNames('card', {
    'card--large': !!large,
    'card--orange': !!isSecondary,
    'card--dark-green': !!isTertiary,
    'card--dark-grey': !!isGrey
  });

  if (linkTo) {
    /** Router Link */
    return (
      <Link to={linkTo} state={linkState} {...rest} className={classses}>
        <CardContent {...rest}></CardContent>
      </Link>
    );
  }

  return (
    <a {...rest} target="_blank" rel="noopener noreferrer" className={classses}>
      <CardContent {...rest}></CardContent>
    </a>
  );
};

export default Card;

import React from 'react';

export const MinusCircle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8462 12.0127C22.8462 17.917 18.0598 22.7034 12.1555 22.7034C6.25123 22.7034 1.46484 17.917 1.46484 12.0127C1.46484 6.10841 6.25123 1.32202 12.1555 1.32202C18.0598 1.32202 22.8462 6.10841 22.8462 12.0127Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M11.2702 12.7288C10.5405 12.7288 6.72792 12.7288 6.72792 12.7288C6.32536 12.7288 5.99902 12.4025 5.99902 11.9999C5.99902 11.5973 6.32536 11.271 6.72792 11.271L11.2702 11.271C12 11.271 12.2882 11.271 12.728 11.271L17.2701 11.271C17.6727 11.271 17.999 11.5973 17.999 11.9999C17.999 12.4025 17.6727 12.7288 17.2701 12.7288L12.728 12.7288C12 12.7288 12 12.7288 11.2702 12.7288Z"
      fill="white"
    />
  </svg>
);

import NorthlandImage from '../assets/images/generated/regions/northland-780.jpeg';
import AucklandImage from '../assets/images/generated/regions/auckland-780.jpeg';
import TaurangaImage from '../assets/images/generated/regions/tauranga-780.png';
import RotoruaImage from '../assets/images/generated/regions/rotorua-780.jpeg';
import WaikatoImage from '../assets/images/generated/regions/waikato-780.jpeg';
import GisborneImage from '../assets/images/generated/regions/gisborne-780.jpeg';
import HawkesBayImage from '../assets/images/generated/regions/hawkes-bay-780.jpeg';
import TaranakiImage from '../assets/images/generated/regions/taranaki-780.jpeg';
import WhanganuiImage from '../assets/images/generated/regions/whanganui-780.jpeg';
import ManawatuImage from '../assets/images/generated/regions/manawatu-780.jpeg';
import WellingtonImage from '../assets/images/generated/regions/wellington-780.png';
import NelsonImage from '../assets/images/generated/regions/nelson-780.jpeg';
import WestCoastImage from '../assets/images/generated/regions/west-coast-780.jpeg';
import ChristchurchImage from '../assets/images/generated/regions/christchurch-780.jpeg';
import DunedinImage from '../assets/images/generated/regions/dunedin-780.jpeg';
import QueenstownImage from '../assets/images/generated/regions/queenstown-780.jpeg';
import SouthlandImage from '../assets/images/generated/regions/southland-780.jpeg';

export const REGIONS = [
  {
    id: 'northland',
    title: 'Northland',
    description:
      '<p>Northland is a sub-tropical paradise of white sandy beaches, giant kauri forests and outdoor adventures (just 2 hours north of Auckland). Choose from cultural experiences at Waitangi or Manea Footprints, try paddleboarding, fishing or jet skiing and stay in one of our coastal campgrounds overnight listening to kiwi calling and star gazing.</p>',
    ctaUrl: 'https://www.bekiwi.nz/northland',
    image: NorthlandImage,
    labelAlign: 'left'
  },
  {
    id: 'auckland',
    title: 'Auckland',
    description:
      '<p>Tāmaki Makaurau Auckland is a great place to be in summer as the city comes alive with music, sporting and cultural events! Check out Iconic Eats for Auckland’s best dishes and explore the region’s great shopping neighbourhoods. Experience the beautiful Hauraki Gulf Marine Park and find awesome travel and volunteering experiences in the region.</p>',
    ctaUrl: 'https://www.aucklandnz.com/study-work-and-live/study/experience-auckland',
    image: AucklandImage,
    labelAlign: 'right'
  },
  {
    id: 'tauranga',
    title: 'Tauranga',
    description:
      '<p>Come and have fun in sunny <a href="https://www.educationtauranga.co.nz/play-here" target="_blank" rel="noopener noreferrer">Tauranga</a>. You&rsquo;ll find plenty of activities with an adventure, educational or environmental focus. Hike up Mount Maunganui, the iconic landmark at the tip of New Zealand&rsquo;s most popular surf beach, watch the sunrise, and swim in the warm, safe waves of the Pacific Ocean.</p>',
    ctaUrl: 'https://www.bayofplentynz.com/',
    image: TaurangaImage,
    labelAlign: 'left'
  },
  {
    id: 'rotorua',
    title: 'Rotorua',
    description:
      '<p>Rotorua is a multi-cultural hub with forward-thinking educational providers and world-class facilities. Rotorua offers a quality education along with an easily accessible outdoor lifestyle.</p>',
    ctaUrl: 'https://www.rotoruanz.com/visit',
    image: RotoruaImage,
    labelAlign: 'left'
  },
  {
    id: 'waikato',
    title: 'Waikato',
    description:
      '<p>Students studying in the <a href="https://www.waikato.com/learn" target="_blank" rel="noopener noreferrer">Waikato</a> have many of New Zealand&rsquo;s most popular tourist attractions in their backyard. Visit a Hobbiton movie set, ski at Mt Ruapehu, learn to surf, discover Māori culture, explore the Waitomo caves and have fun on the mighty Waikato River – all just 90 minutes from Auckland.</p>',
    ctaUrl: 'https://www.waikatonz.com/hot-deals/',
    image: WaikatoImage,
    labelAlign: 'right'
  },
  {
    id: 'gisborne',
    title: 'Gisborne',
    description:
      '<p>With a warm, sunny climate, beautiful beaches and an unspoilt natural wilderness to explore, Gisborne is a relaxed, stress-free study destination. It’s a great study choice if you want to improve your English at secondary school level, learn to become a winemaker or study one of many other vocational subjects on offer at the leading Eastern Institute of Technology.</p>',
    ctaUrl: 'https://tairawhitigisborne.co.nz/',
    image: GisborneImage,
    labelAlign: 'left'
  },
  {
    id: 'hawkes-bay',
    title: "Hawke's Bay",
    description:
      '<p><a href="https://learninghawkesbay.nz/" target="_blank" rel="noopener noreferrer">Hawke’s Bay</a> offers world class education, personal safety, exciting cultural and sport activities, beautiful natural environment and a very comfortable climate. Hawke’s Bay people are friendly and welcoming, and happy to share our wonderful lifestyle with all visitors, international students and migrants. We have no community cases of Covid-19 and our region has a higher than average Covid-19 vaccination rate in NZ with some areas exceeding 90%.</p>',
    ctaUrl: 'https://www.hawkesbaynz.com/',
    image: HawkesBayImage,
    labelAlign: 'left'
  },
  {
    id: 'taranaki',
    title: 'Taranaki',
    description:
      '<p>Peaceful bushwalks, bustling cafes, a thriving arts scene – Taranaki has it all. In December 2021, the region&rsquo;s main centre, New Plymouth, was named the world&rsquo;s most liveable city, for a population between 75,000-150,000, by the International Awards for Liveable Communities. Come and experience it yourself and see what more the region offers, including South Taranaki&rsquo;s renowned Surf Highway 45. For more, check out our visitor information page.</p>',
    ctaUrl: 'http://visit.taranaki.info/',
    image: TaranakiImage,
    labelAlign: 'right'
  },
  {
    id: 'whanganui',
    title: 'Whanganui',
    description:
      '<p>Welcome to Whanganui. This is our place; where history is full of stories, legends and rich legacy. Where a thriving arts scene, creativity and evolving culture inspire our modern lives. Where breath-taking natural landscapes capture imaginations. This is a place of exchange, where new people and new ideas are welcomed, and difference is valued.</p>',
    ctaUrl: 'https://discoverwhanganui.nz/visit/',
    image: WhanganuiImage,
    labelAlign: 'right'
  },
  {
    id: 'manawatu',
    title: 'Manawatu',
    description:
      '<p>Manawatū has everything students need for a great summer – vibrant cultural celebrations, quiet beaches, bustling shops and cafes, walking trails and riverside bike rides. Whatever you&rsquo;re looking for, you&rsquo;ll find it here. Choose from a range of student activities taking place across Palmerston North city and Manawatū.</p>',
    ctaUrl: 'https://www.ManawatuNZ.co.nz',
    image: ManawatuImage
  },
  {
    id: 'wellington',
    title: 'Wellington',
    description:
      '<p>Famous for its vibrant, creative culture, <a href="https://www.wellingtonnz.com/work-and-study/study-in-wellington/international-student-programmes/summer-programme/" target="_blank" rel="noopener noreferrer">Wellington</a> is a cosmopolitan city with an energetic personality. Experience life in New Zealand’s capital over summer as part of a short study programme or English language course, or just enjoy our fun events and strolling through the city.</p>',
    ctaUrl: 'http://www.wellingtonnz.com/visit',
    image: WellingtonImage,
    labelAlign: 'left'
  },
  {
    id: 'nelson-marlborough',
    title: 'Nelson-Tasman Marlborough',
    description:
      '<p>Whether your passion is adventure sport or relaxing in the sunshine, the <a href="https://www.nelsontasman.nz/" target="_blank" rel="noopener noreferrer">Nelson, Tasman and Marlborough</a> region are as much a lifestyle as a study destination. You’ll live in an extraordinary natural paradise while studying at one of the region’s high achieving schools or gaining an internationally recognised tertiary qualification to set you on the path to your future career.</p>',
    ctaUrl: 'https://marlboroughnz.com/',
    image: NelsonImage,
    labelAlign: 'right'
  },
  {
    id: 'west-coast',
    title: 'West Coast',
    description:
      '<p>With its rainforests, mountains, glaciers and rivers, the wild <a href="https://westcoast.co.nz/live/mahi-tai-poutini/" target="_blank" rel="noopener noreferrer">West Coast</a> of the South Island is an unforgettable outdoor adventure and study destination. Gain technical and vocational skills for your future career or study at one of the coast’s safe, modern schools while enjoying an uncrowded scenic wonderland far away from the pressures of city living.</p>',
    ctaUrl: 'https://westcoast.co.nz/',
    image: WestCoastImage,
    labelAlign: 'right'
  },
  {
    id: 'canterbury',
    title: 'Christchurch &ndash; Canterbury',
    label: 'Christchurch',
    description:
      '<p>Summer is here and it’s time to explore our amazing <a href="https://www.bekiwi.nz/christchurch-and-canterbury" target="_blank" rel="noopener noreferrer">Christchurch</a> and Canterbury region. Pull out the shorts, slap on the sunscreen, and make the most of the summer weather. From scenic walks to the best ice-cream spots in the city, we have what you need for your best summer yet!</p>',
    ctaUrl: 'https://www.christchurchnz.com/explore/seasonal-guides/summer',
    image: ChristchurchImage,
    labelAlign: 'left'
  },
  {
    id: 'otago',
    title: 'Dunedin &ndash; Otago',
    label: 'Dunedin',
    description:
      '<p>Summer holidays are a time to relax and explore something new. Join us in Dunedin for summer adventures full of nature walks, beaches, great food & shopping plus unique wildlife. You’ll find a compact walkable city packed with activities to choose from and a warm welcome from the locals.</p>',
    ctaUrl: 'https://www.dunedinnz.com/visit/see-and-do',
    image: DunedinImage,
    labelAlign: 'left'
  },
  {
    id: 'queenstown',
    title: 'Queenstown',
    description:
      '<p><a href="https://www.queenstownnz.nz" target="_blank" rel="noopener noreferrer">Queenstown</a> is New Zealand’s most popular holiday destination, so why not come and enjoy a summer of fun, adventure, learning, friendship and connecting with nature. The Southern Hemisphere’s premier lake resort, with an array of accommodation and work options, Queenstown is the perfect place to compliment your Kiwi education journey.</p>',
    ctaUrl: 'http://www.studyqueenstown.com/summer',
    image: QueenstownImage,
    labelAlign: 'right'
  },
  {
    id: 'southland',
    title: 'Southland',
    description:
      '<p>New Zealand&rsquo;s southernmost region is famous for its beautiful coastlines, wild scenery and the genuine welcome it extends to newcomers. A warm, supportive environment helps school and tertiary students to fulfil their potential, and there are opportunities to build a future career in Southland.</p>',
    ctaUrl: 'http://www.southlandnz.com/',
    image: SouthlandImage,
    labelAlign: 'right'
  }
];

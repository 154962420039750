import React from 'react';
import { Router } from '@reach/router';
import './styles';
import Footer from './components/Footer';

import Home from './views/Home';
import Map from './views/Map';
import NotFound from './views/NotFound';

function App() {
  return (
    <>
      <Router>
        <Home path="/" />
        <Map path="/map" />
        <NotFound path="*" />
      </Router>

      <Footer />
    </>
  );
}

export default App;

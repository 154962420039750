import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import TagManager from 'react-gtm-module';

const tagManagerArgsID = {
  gtmId: 'GTM-KSG79GT',
  events: {
    formSubmitted: 'Form Submitted'
  }
};

let tagManagerArgs = { ...tagManagerArgsID };

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  const tagMangerArgsUAT = {
    auth: 'xNmRUq6AkWqSq_wIYg4mrQ',
    preview: 'env-3',
    cookies_win: 'x'
  };
  tagManagerArgs = { ...tagManagerArgsID, ...tagMangerArgsUAT };
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

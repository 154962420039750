import React from 'react';

export const PlusCircle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8462 12.0127C22.8462 17.917 18.0598 22.7034 12.1555 22.7034C6.25123 22.7034 1.46484 17.917 1.46484 12.0127C1.46484 6.10841 6.25123 1.32202 12.1555 1.32202C18.0598 1.32202 22.8462 6.10841 22.8462 12.0127Z"
      stroke="#CDD1D7"
      strokeWidth="1.5"
    />
    <path
      d="M11.2702 11.3447C11.2898 11.3447 11.3085 11.337 11.3224 11.3231C11.3362 11.3093 11.344 11.2906 11.344 11.271L11.344 6.72892C11.344 6.36707 11.6373 6.07374 11.9991 6.07374C12.361 6.07374 12.6543 6.36707 12.6543 6.72892L12.6543 11.271C12.6543 11.3117 12.6873 11.3447 12.728 11.3447L17.2701 11.3447C17.632 11.3447 17.9253 11.6381 17.9253 11.9999C17.9253 12.3617 17.632 12.6551 17.2701 12.6551L12.728 12.6551C12.7085 12.6551 12.6897 12.6628 12.6759 12.6767C12.6621 12.6905 12.6543 12.7092 12.6543 12.7288L12.6543 17.2711C12.6543 17.633 12.361 17.9263 11.9991 17.9263C11.6373 17.9263 11.344 17.633 11.344 17.2711L11.344 12.7288C11.344 12.6881 11.311 12.6551 11.2702 12.6551L6.72792 12.6551C6.36608 12.6551 6.07274 12.3617 6.07274 11.9999C6.07274 11.6381 6.36608 11.3447 6.72792 11.3447L11.2702 11.3447Z"
      fill="#CDD1D7"
      stroke="#CDD1D7"
      strokeWidth="0.147435"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

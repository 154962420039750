import React, { useEffect } from 'react';
import { RegionMap } from '../components/region-map';
import Banner from '../components/Banner';
import CTABanner from '../components/CTABanner';
import Header from '../components/Header';

function Map({ location }) {
  const selection = location?.state?.selection || 'neutral';

  // Attach share button when it appears
  useEffect(() => {
    setTimeout(() => {
      window.addthis.button('.share');
    }, 500);
  }, []);

  return (
    <>
      <Header child="What&rsquo;s happening around NZ this Summer?" showHomeLink={true} />
      <Banner>
        <h1>What&rsquo;s happening around NZ this Summer?</h1>
        <p className="banner__content__subtitle hide-sm-up">Choose a place</p>
      </Banner>
      <section className="section--map-blue">
        <div className="container container--no-padding">
          <RegionMap />
        </div>
      </section>
      <section className="section--compact section--dark-grey">
        <div className="container">
          <CTABanner linkTo={`/#${selection}`} linkState={{ fromMap: true }} />
        </div>
      </section>
    </>
  );
}

export default Map;

import React from 'react';
import { ButtonLink } from './ButtonLink';
import { Chevron } from '../svgs';

const CTABanner = ({ linkTo, linkState = {} }) => (
  <div className="cta-banner">
    <div className="cta-banner__content">
      <h3 className="text-white">Sign Up to NauMai NZ</h3>
      <p>
        NauMai NZ is the official government site for international students studying in New
        Zealand.
        <br />
        Get trusted information, help and advice to have a great experience living and studying in
        New Zealand.
      </p>
    </div>
    <div className="cta-banner__button">
      <ButtonLink linkTo={linkTo} linkState={linkState} text="Sign Up" icon={Chevron} />
    </div>
  </div>
);

export default CTABanner;

import React from 'react';
import { REGIONS } from '../../data/regions';
import classNames from 'classnames';
import { Marker } from '../../svgs';

/**
 * Render region labels
 */
export const MapLabels = ({ selectedRegion, setSelectedRegion }) => {
  return (
    <div className="enz-map-labels">
      {REGIONS.map((region) => {
        const { id, title, label, labelAlign } = region;
        const isSelected = id === selectedRegion?.id;
        return (
          <button
            key={id}
            type="button"
            className={classNames(
              `enz-map-labels__label enz-map-labels__label--${id} ${labelAlign}`,
              {
                'is-selected': isSelected
              }
            )}
            onClick={() => setSelectedRegion(region)}>
            <div className="enz-map-labels__icon">
              <span className="icon">
                <Marker />
              </span>
            </div>
            {label || title}
          </button>
        );
      })}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

const Article = ({ children, category, author, title, image, age, ...rest }) => {
  const classes = classNames('article', {
    [`article--${category?.toLowerCase()}`]: !!category
  });

  return (
    <a {...rest} className={classes}>
      <div className="article__image">
        <img src={image} alt="" />
      </div>
      {category && <div className="article__category">{category}</div>}
      <div className="article__content">
        <h4>{title}</h4>
        <div className="article__author">
          <p>
            {author} {'\u2022'} {age}
          </p>
        </div>
      </div>
    </a>
  );
};

export default Article;

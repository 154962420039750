import React from 'react';
import Card from '../Card';
import { Chevron } from '../../svgs';

const ModuleCardContent = ({
  selection,
  href,
  image,
  title,
  paragraph1,
  paragraph2,
  ctaText,
  linkTo,
  isSecondary = false,
  isTertiary = false,
  isFullWidth = false,
  isGrey = false,
  ...rest
}) => (
  <Card
    href={href}
    image={image}
    title={title}
    linkTo={linkTo}
    linkState={{ selection }}
    isSecondary={isSecondary}
    isTertiary={isTertiary}
    large={isFullWidth}
    isGrey={isGrey}
    {...rest}>
    {paragraph1 && <p>{paragraph1}</p>}
    {paragraph2 && <p>{paragraph2}</p>}
    <div>
      <span className="button button--primary-inverse">
        <span>{ctaText}</span>
        <span className="icon">
          <Chevron />
        </span>
      </span>
    </div>
  </Card>
);

export default ModuleCardContent;

import React from 'react';
import classNames from 'classnames';
import Wellbeing from '../svgs/Wellbeing';
import Exploring from '../svgs/Exploring';
import Working from '../svgs/Working';
import { Chevron } from '../svgs/Chevron';

function Toggle({ onChange, selection }) {
  const handleClick = (e) => {
    e.preventDefault();
    const selection = e.target.getAttribute('href').substr(1);

    onChange(selection);
  };

  return (
    <div
      className={classNames('toggle', {
        'toggle--active': selection !== null && selection !== 'neutral'
      })}>
      <p className="toggle__intro">Select an option</p>
      <a
        onClick={handleClick}
        href="#wellbeing"
        className={classNames('toggle__option', {
          'toggle__option--selected': selection === 'wellbeing'
        })}>
        <div className="toggle__icon">
          <Wellbeing />
        </div>
        <div className="toggle__label">
          <p>Stay for summer and stay well</p>
        </div>
        <div className="toggle__arrow">
          <Chevron />
        </div>
      </a>
      <a
        onClick={handleClick}
        href="#exploring"
        className={classNames('toggle__option', {
          'toggle__option--selected': selection === 'exploring'
        })}>
        <div className="toggle__icon">
          <Exploring />
        </div>
        <div className="toggle__label">
          <p>Stay for summer and see the country</p>
        </div>
        <div className="toggle__arrow">
          <Chevron />
        </div>
      </a>
      <a
        onClick={handleClick}
        href="#working"
        className={classNames('toggle__option', {
          'toggle__option--selected': selection === 'working'
        })}>
        <div className="toggle__icon">
          <Working />
        </div>
        <div className="toggle__label">
          <p>Stay for summer and find employment</p>
        </div>
        <div className="toggle__arrow">
          <Chevron />
        </div>
      </a>

      <div className="toggle__next">
        <Chevron />
      </div>
    </div>
  );
}

export default Toggle;

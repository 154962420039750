import React from 'react';

export default () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__primary">
          <div className="footer__links">
            <p className="footer__title">Quick Links</p>

            <ul className="footer__list">
              <li>
                <a href="https://naumainz.studyinnewzealand.govt.nz/about-naumai-nz">
                  About NauMai NZ
                </a>
              </li>
              <li>
                <a href="https://naumainz.studyinnewzealand.govt.nz/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://naumainz.studyinnewzealand.govt.nz/sitemap">Sitemap</a>
              </li>
            </ul>

            <p className="footer__title">Find us on</p>

            <ul className="footer__list-social">
              <li>
                <a
                  href="https://www.facebook.com/studyinnewzealand"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={require('../assets/images/icons/facebook.png')} alt="" />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/studyinnewzealand/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={require('../assets/images/icons/instagram.png')} alt="" />
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__imprimateur">
            <img
              className="footer__coatofarms"
              src={require('../assets/images/coat-of-arms.svg')}
              alt="New Zealand Government Emblem"
            />
            <div className="footer__imprimateur-content">
              <p>
                NauMai NZ is the official government site providing trusted information and help for
                international students studying or about to study in New&nbsp;Zealand.
              </p>
            </div>
          </div>
        </div>
        <div className="footer__secondary">
          <p>All content Education New&nbsp;Zealand {new Date().getFullYear()} ©</p>

          <a href="https://www.enz.govt.nz" target="_blank" rel="noopener noreferrer">
            <img
              className="footer__logo"
              src={require('../assets/images/enz-logo.svg')}
              alt="Education New Zealand logo"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export const RegionImage = (props) => {
  const { image, alt } = props;
  const [loaded, setLoaded] = useState(false);

  // preload image
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = image; // by setting an src, you trigger browser download
  }, [image]);

  return (
    <div className="enz-region-info__image">
      <div
        className={classNames('enz-region-info__image__ratio', {
          'enz-region-info__image__ratio--loaded': loaded
        })}>
        {loaded && <img alt={alt} src={image} />}
      </div>
    </div>
  );
};

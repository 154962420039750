import React from 'react';

function Logo() {
  return (
    <svg viewBox="0 0 209 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0846 30.5745V1.60788H19.7947V19.443L6.22179 1.60788H0.345703V30.5745H6.6356V12.16L20.6637 30.5745H26.0846Z"
        fill="currentColor"
      />
      <path
        d="M49.4527 30.5745V17.705C49.4527 14.698 48.6665 12.3531 47.094 10.6703C45.5215 8.98747 43.0111 8.14606 39.5627 8.14606C37.6868 8.14606 36.0867 8.31159 34.7625 8.64263C33.4383 8.97368 32.1693 9.40128 30.9555 9.92544L32.5279 14.7256C33.5487 14.3394 34.5142 14.0497 35.4246 13.8566C36.3626 13.6359 37.4385 13.5256 38.6523 13.5256C40.2248 13.5256 41.411 13.8704 42.2111 14.5601C43.0111 15.2498 43.4111 16.2567 43.4111 17.5809V17.9533C42.6111 17.6775 41.7835 17.4568 40.9283 17.2912C40.1006 17.1257 39.1213 17.043 37.9902 17.043C36.666 17.043 35.4522 17.1947 34.3487 17.4981C33.2728 17.774 32.3348 18.2016 31.5348 18.781C30.7624 19.3603 30.1554 20.0913 29.714 20.9741C29.2726 21.8569 29.0519 22.9052 29.0519 24.1191V24.2019C29.0519 25.3053 29.2451 26.2847 29.6313 27.1399C30.0451 27.9675 30.5968 28.671 31.2865 29.2503C31.9762 29.8297 32.7762 30.271 33.6866 30.5745C34.6246 30.8504 35.6177 30.9883 36.666 30.9883C38.2109 30.9883 39.5213 30.74 40.5972 30.2435C41.6731 29.7193 42.5973 29.0296 43.3697 28.1744V30.5745H49.4527ZM43.4939 22.7535C43.4939 23.9122 43.0387 24.8502 42.1283 25.5674C41.2455 26.2847 40.0593 26.6433 38.5695 26.6433C37.5488 26.6433 36.7212 26.4088 36.0867 25.9398C35.4522 25.4709 35.1349 24.8088 35.1349 23.9536V23.8708C35.1349 22.8777 35.5212 22.119 36.2936 21.5949C37.066 21.0707 38.1282 20.8086 39.4799 20.8086C40.2524 20.8086 40.9696 20.8914 41.6317 21.0569C42.3214 21.1948 42.9421 21.388 43.4939 21.6362V22.7535Z"
        fill="currentColor"
      />
      <path
        d="M73.0177 30.5745V8.39435H66.7278V20.7672C66.7278 22.2569 66.3692 23.388 65.6519 24.1605C64.9622 24.9053 64.0105 25.2778 62.7966 25.2778C61.5828 25.2778 60.6448 24.9053 59.9827 24.1605C59.3206 23.388 58.9896 22.2569 58.9896 20.7672V8.39435H52.6997V22.7535C52.6997 25.3191 53.348 27.333 54.6446 28.7951C55.9688 30.2573 57.8309 30.9883 60.231 30.9883C61.0311 30.9883 61.7483 30.8918 62.3828 30.6987C63.0173 30.5055 63.5967 30.2435 64.1208 29.9124C64.645 29.5814 65.114 29.2089 65.5278 28.7951C65.9692 28.3537 66.3692 27.8985 66.7278 27.4296V30.5745H73.0177Z"
        fill="currentColor"
      />
      <path
        d="M106.276 30.5745V1.60788H99.4069L91.7929 13.8566L84.1788 1.60788H77.3095V30.5745H83.5581V11.7876L91.6273 24.0363H91.7929L99.9449 11.6634V30.5745H106.276Z"
        fill="currentColor"
      />
      <path
        d="M129.649 30.5745V17.705C129.649 14.698 128.863 12.3531 127.291 10.6703C125.718 8.98747 123.208 8.14606 119.759 8.14606C117.883 8.14606 116.283 8.31159 114.959 8.64263C113.635 8.97368 112.366 9.40128 111.152 9.92544L112.725 14.7256C113.745 14.3394 114.711 14.0497 115.621 13.8566C116.559 13.6359 117.635 13.5256 118.849 13.5256C120.422 13.5256 121.608 13.8704 122.408 14.5601C123.208 15.2498 123.608 16.2567 123.608 17.5809V17.9533C122.808 17.6775 121.98 17.4568 121.125 17.2912C120.297 17.1257 119.318 17.043 118.187 17.043C116.863 17.043 115.649 17.1947 114.545 17.4981C113.47 17.774 112.532 18.2016 111.732 18.781C110.959 19.3603 110.352 20.0913 109.911 20.9741C109.469 21.8569 109.249 22.9052 109.249 24.1191V24.2019C109.249 25.3053 109.442 26.2847 109.828 27.1399C110.242 27.9675 110.794 28.671 111.483 29.2503C112.173 29.8297 112.973 30.271 113.883 30.5745C114.821 30.8504 115.814 30.9883 116.863 30.9883C118.408 30.9883 119.718 30.74 120.794 30.2435C121.87 29.7193 122.794 29.0296 123.566 28.1744V30.5745H129.649ZM123.691 22.7535C123.691 23.9122 123.235 24.8502 122.325 25.5674C121.442 26.2847 120.256 26.6433 118.766 26.6433C117.746 26.6433 116.918 26.4088 116.283 25.9398C115.649 25.4709 115.332 24.8088 115.332 23.9536V23.8708C115.332 22.8777 115.718 22.119 116.49 21.5949C117.263 21.0707 118.325 20.8086 119.677 20.8086C120.449 20.8086 121.166 20.8914 121.828 21.0569C122.518 21.1948 123.139 21.388 123.691 21.6362V22.7535Z"
        fill="currentColor"
      />
      <path
        d="M139.807 5.95288V0.366455H133.186V5.95288H139.807ZM139.641 30.5745V8.39435H133.352V30.5745H139.641Z"
        fill="currentColor"
      />
      <path
        d="M180.356 30.5745V1.60788H174.066V19.443L160.493 1.60788H154.617V30.5745H160.907V12.16L174.935 30.5745H180.356Z"
        fill="currentColor"
      />
      <path
        d="M208.566 30.5745V24.9881H192.634L208.566 6.44945V1.60788H184.937V7.1943H200.372L184.441 25.7329V30.5745H208.566Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Logo;

import React from 'react';
import { Link } from '@reach/router';

/**
 * @param {
 *  url: string
 *  text: string
 *  icon: JSX.Element
 *  buttonColor: string (primary | secondary | primary-inverse | secondary-inverse | orange)
 *  openInNewWindow: boolean
 * } props
 */
export const ButtonLink = (props) => {
  const { url, linkTo, linkState, text, icon, openInNewWindow, buttonColor = 'primary' } = props;
  const Icon = icon ? icon : null;

  const linkProps = {
    className: `button button--${buttonColor}`,
    target: openInNewWindow ? '_blank' : null,
    rel: openInNewWindow ? 'noopener noreferrer' : null
  };

  if (linkTo) {
    /** Router Link */
    return (
      <Link to={linkTo} state={linkState} {...linkProps}>
        <span>{text}</span>
        {Icon && (
          <span className="icon">
            <Icon />
          </span>
        )}
      </Link>
    );
  }
  return (
    <a href={url} {...linkProps}>
      <span>{text}</span>
      {Icon && (
        <span className="icon">
          <Icon />
        </span>
      )}
    </a>
  );
};
